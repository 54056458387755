<template>
  <section class="users">
    <Header>Пользователи</Header>
    <div class="users__main">
      <div class="tools">
        <Search placeholder="Поиск" v-model="searchQuery" class="tool" />
        <h2 class="users__heading">Фильтры</h2>
        <div class="tools__filters">
          <SelectButtons
            :items="roleFilterItems"
            v-model="pagination.options.role"
          />
          <SelectButtons
            :items="verifiedDropdownItems"
            v-model="pagination.options.verified"
          />
        </div>
        <h2 class="users__heading">Статистика</h2>
        <div class="users__stats stats">
          <div class="stats__item">
            <p class="stats__title">
              {{ numberOfUsers }}
            </p>
            <p class="stats__subtitle">пользователей</p>
          </div>
          <div
            class="stats__item stats__item--clickable"
            @click="openActiveUsersModal"
          >
            <p class="stats__title">
              {{ numberOfUsersOnline }}
            </p>
            <p class="stats__subtitle">активных сессий</p>
          </div>
        </div>
      </div>
      <div class="users__list">
        <div class="users__loading" v-if="isLoading">
          <LoadingLogo />
        </div>
        <div class="users__empty" v-else-if="!users.length">
          <h3>Пользователи не найдены</h3>
        </div>
        <div class="users-table" v-else>
          <Table>
            <table>
              <thead>
                <th>Фото</th>
                <th>Имя</th>
                <th>Почта</th>
                <th>
                  <button
                    :class="[
                      'table-sortable',
                      {
                        'table-sortable-desc':
                          pagination.options.order == 'DESC',
                      },
                      {
                        'table-sortable-asc': pagination.options.order == 'ASC',
                      },
                    ]"
                    @click="toggleSort()"
                  >
                    Дата регистрации
                  </button>
                </th>
                <th>Действия</th>
              </thead>
              <tbody>
                <h3
                  style="white-space: nowrap"
                  v-if="
                    !searchUserLoading && !filteredUsers.length && searchQuery
                  "
                >
                  Пользователей не найдено
                </h3>
                <tr class="loading" v-if="searchUserLoading">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr
                  v-for="user in filteredUsers"
                  :key="user.uuid"
                  @click="selectUser(user)"
                >
                  <td>
                    <div
                      v-if="user.avatar_is_loading"
                      class="table-cover-loading table-center"
                    ></div>
                    <Transition name="resize">
                      <div
                        class="table-cover table-center"
                        v-show="
                          file(user.profile?.avatar_url)?.base64 &&
                          !user.avatar_is_loading
                        "
                      >
                        <img
                          :src="file(user.profile?.avatar_url)?.base64"
                          alt="Avatar"
                        />
                      </div>
                    </Transition>
                    <Transition name="resize">
                      <div
                        class="table-cover table-center"
                        v-show="
                          !file(user.profile?.avatar_url)?.base64 &&
                          !user.avatar_is_loading
                        "
                      >
                        <img
                          :src="require('@/assets/placeholder.png')"
                          alt="Avatar Placeholder"
                        />
                      </div>
                    </Transition>
                  </td>
                  <td>
                    {{ getUserName(user) }}
                  </td>
                  <td>
                    <Chip :color="user.verified_at ? 'green' : 'gray'">
                      {{ user.email }}
                    </Chip>
                  </td>
                  <td>
                    {{ getFormattedDate(user.created_at) }}
                  </td>
                  <td>
                    <div class="table-actions" @click.stop>
                      <button @click="copyUserURL(user)">
                        <img src="@/assets/icons/copy.svg" alt="" />
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Table>
          <Pagination
            v-if="!searchQuery"
            :options="pagination.options"
            :meta="pagination.meta"
            @options-change="
              (newOptions) => {
                pagination.options = newOptions;
              }
            "
          />
        </div>
      </div>
    </div>
    <Transition name="fade">
      <UserModal
        v-if="isUserModalVisible && selectedUser"
        :user="selectedUser"
        @close="closeUserModal"
      />
    </Transition>
    <Transition name="fade">
      <Modal v-if="isActiveUsersModalOpened" @close="closeActiveUsersModal">
        <div class="active-users">
          <h2 class="active-users__heading">Активные пользователи</h2>
          <div class="active-users__loader" v-if="isActiveUsersLoading">
            <LoadingLogo />
          </div>
          <div class="active-users__list" v-else-if="activeUsers?.length">
            <UserListItem
              v-for="user in activeUsers"
              :key="user.uuid"
              :user="user"
              @click="selectUser(user)"
            />
          </div>
          <div class="active-users__empty" v-else>
            В данный момент активных пользователей нет.
          </div>
        </div>
      </Modal>
    </Transition>
  </section>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import copyToClipboard from "@/utils/copyToClipboard";
import getUserName from "@/utils/getUserName";
import getFormattedDate from "@/utils/getFormattedDate";
import UserRoles from "@/utils/UserRoles";
import Modal from "@/components/Modal.vue";
import Header from "@/components/Header.vue";
import Search from "@/components/Search.vue";
import LoadingLogo from "@/components/LoadingLogo.vue";
import Pagination from "@/components/Pagination.vue";
import UserModal from "@/components/UserModal.vue";
import UserListItem from "@/components/UserListItem.vue";
import SelectButtons from "@/components/SelectButtons.vue";
import Table from "@/components/Table.vue";
import Chip from "@/components/Chip.vue";

export default {
  components: {
    Modal,
    Header,
    Search,
    LoadingLogo,
    Pagination,
    UserModal,
    UserListItem,
    SelectButtons,
    Table,
    Chip,
  },
  data() {
    return {
      UserRoles,

      isLoading: false,
      searchQuery: "",

      selectedUserUuid: "",
      isUserModalVisible: false,
      isActiveUsersModalOpened: false,
      isActiveUsersLoading: false,

      searchUserLoading: false,
      searchUserPointer: false,
      searchedUser: [],

      verifiedDropdownItems: [
        {
          value: undefined,
          name: "Все",
          key: this.value,
        },
        {
          value: 1,
          name: "Верифицирован",
          key: this.value,
        },
        {
          value: 0,
          name: "Не верифицирован",
          key: this.value,
        },
      ],

      pagination: {
        options: {
          page: 1,
          take: 20,
          role: null,
          verified: null,
          order: "DESC",
        },
        meta: {},
      },
    };
  },
  mounted() {
    this.fetchUsersRequest();
    this.fetchUsersMetadata();
    this.selectUserFromUrl();
  },
  methods: {
    ...mapActions([
      "fetchUsers",
      "fetchFile",
      "fetchUserPurchases",
      "fetchUsersMetadata",
      "fetchUser",
      "fetchActiveUsers",
      "fetchCredential",
      "fetchProfileByCredential",
      "searchUser",
    ]),

    ...mapMutations(["setUser"]),
    copyToClipboard,
    getUserName,
    getFormattedDate,

    copyUserURL(user) {
      navigator.clipboard.writeText(
        `${window.location.origin}/users/${user.uuid}`
      );
    },

    selectUserFromUrl() {
      const uuid = this.$route.params.user_uuid;

      if (uuid) {
        this.fetchUser(uuid).then((user) => {
          this.selectedUserUuid = user.uuid;
          this.isUserModalVisible = true;
          this.fetchUserPurchasesRequest(user.uuid);
        });
      }
    },

    toggleSort() {
      switch (this.pagination.options.order) {
        case undefined:
          this.pagination.options.order = "ASC";
          break;
        case "ASC":
          this.pagination.options.order = "DESC";
          break;
        case "DESC":
          this.pagination.options.order = undefined;
          break;
      }
    },

    closeActiveUsersModal() {
      this.isActiveUsersModalOpened = false;
    },

    openActiveUsersModal() {
      this.isActiveUsersModalOpened = true;
      this.isActiveUsersLoading = true;
      this.fetchActiveUsers().finally(() => {
        this.isActiveUsersLoading = false;
      });
    },

    closeUserModal() {
      this.isUserModalVisible = false;
      this.selectedUserUuid = "";
      this.$router.push({ name: "Users", params: { release_uuid: "" } });
    },

    selectUser(user) {
      this.$router.push({ name: "Users", params: { user_uuid: user.uuid } });
      this.closeActiveUsersModal();
      this.selectedUserUuid = user.uuid;
      this.isUserModalVisible = true;
      this.fetchUserPurchasesRequest(user.uuid);
    },

    fetchUserPurchasesRequest(userUuid) {
      const purchases = this.userPurchases(userUuid);
      if (!purchases.length) {
        this.fetchUserPurchases(userUuid);
      }
    },

    async fetchUsersRequest() {
      this.isLoading = true;
      const payload = this.pagination.options;
      let users = [];

      this.fetchUsers(payload)
        .then((response) => {
          this.pagination.meta = response.data.meta;
          users = response.data.data;
          users.forEach((user) => {
            user.isActive = this.isUserOnline(user);
          });

          this.fetchUserAvatars(users);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    async fetchUserAvatars(users) {
      console.log("fetchUserAvatars");
      for (const user of users) {
        user.avatar_is_loading = true;
        await this.fetchFile(user.profile?.avatar_url);
        user.avatar_is_loading = false;
        this.setUser(user);
        console.log(user.avatar_is_loading);
      }
    },

    isUserOnline(user) {
      return this.getUsersMetadata?.sessions?.some((session) => {
        return session.credential.email === user.email;
      });
    },
  },
  computed: {
    ...mapGetters([
      "users",
      "file",
      "userPurchases",
      "getUsersMetadata",
      "activeUsers",
      "getUser",
    ]),

    roleFilterItems() {
      const userRolesList = Object.values(this.UserRoles);
      const items = userRolesList.map((role) => ({
        key: role.key,
        name: role.title,
        value: role.key,
      }));

      return items;
    },

    selectedUser() {
      if (!this.selectedUserUuid) return null;
      return this.getUser(this.selectedUserUuid);
    },

    filteredUsers() {
      if (this.searchQuery) {
        return this.searchedUser;
      } else {
        return this.users.filter((user) => {
          const name = this.getUserName(user);
          const userData = [name, user.email].join(" ");
          return userData
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase());
        });
      }
    },

    numberOfUsers() {
      return this.getUsersMetadata?.credentials?.length ?? "0";
    },

    numberOfUsersOnline() {
      return this.getUsersMetadata?.sessions?.length ?? "0";
    },
  },
  watch: {
    searchQuery() {
      this.searchUserLoading = true;
      window.clearTimeout(this.searchUserPointer);
      this.searchUserPointer = null;
      this.searchedUser = [];
      this.searchUserPointer = setTimeout(() => {
        this.searchUser(this.searchQuery)
          .then((response) => {
            this.searchedUser = [response.data];
          })
          .finally(() => {
            this.searchUserLoading = false;
          });
      }, 500);
    },
    "pagination.options": {
      deep: true,
      immediate: true,
      handler() {
        this.fetchUsersRequest();
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/_shared.scss";

.loading {
  height: 68px;
  animation: loading 2s linear infinite alternate;
}

@keyframes loading {
  0% {
    background-color: #949494; /* начальный серый цвет */
  }
  100% {
    background-color: #3f4146; /* конечный синий цвет */
  }
}

.users {
  padding-top: 90px;
  color: $white;

  &__main {
    display: flex;
  }

  &__loading {
    flex: 1;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
  }
}

.tools {
  padding-top: 30px;
  height: min-content;
  width: 480px;
  margin-right: 25px;

  .tool {
    margin-bottom: 20px;
  }

  &__filters {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 8px 0 24px;
  }
}
.users__list {
  flex-grow: 1;
  height: 100%;
  padding-top: 30px;
}
.stats {
  background: #3f4146;
  border-radius: 8px;
  padding: 24px 32px;
  display: flex;
  gap: 48px;
  justify-content: center;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;

    &--clickable {
      cursor: pointer;
      transition: 0.15s;

      &:active {
        transition: 0.15s;
        transform: scale(0.95);
      }
    }
  }

  &__title {
    font-size: 32px;
    font-weight: 700;
    margin: 0;
  }

  &__subtitle {
    font-size: 15px;
    font-weight: 500;
    color: #9c9c9c;
    margin: 0;
  }
}

.active-users {
  width: 800px;
  max-height: 600px;
  overflow-y: auto;
  margin: 16px;
  background: black;
  border-radius: 9px;
  padding: 25px;

  &__heading {
    padding-left: 16px;
    margin-bottom: 24px;
  }

  &__loader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 48px 0;
  }

  &__list {
  }

  &__empty {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 48px 0;
    font-size: 18px;
  }
}

.resize-enter-active,
.resize-leave-active {
  transition: all 0.3s;
}

.resize-enter-from,
.resize-leave-to {
  transform: scale(0.6);
  opacity: 0.7;
}
</style>
